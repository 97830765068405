import { navigate } from "gatsby";
import React, { useEffect, useState } from "react";
import { HomePageLayout } from "../components/layouts/HomePageLayout/HomePageLayout";

const Discount = () => {
  const [discountData, setDiscountData] = useState<any>();
  const getDiscountData = async (discountCodeName: string) => {
    try {
      const url = window.location.href;
      const lastIndex = url.indexOf("discounts");
      const urlName = url.slice(0, lastIndex);
      const response = await fetch(`${urlName}.netlify/functions/getDiscounts`);
      if (response.status === 200 && response) {
        const bodyData = await response.text();
        const data = JSON.parse(bodyData);
        const discountArray = data?.data?.codeDiscountNodes?.edges;
        const discount = discountArray.find(
          (el: any) =>
            el?.node?.codeDiscount?.codes?.edges[0]?.node?.code ===
            discountCodeName
        );
        const discountData = {
          name: discount?.node?.codeDiscount?.codes?.edges[0]?.node?.code,
          value:
            discount?.node?.codeDiscount?.customerGets?.value?.__typename ===
            "DiscountAmount"
              ? discount?.node?.codeDiscount?.customerGets?.value?.amount
                  ?.amount
              : discount?.node?.codeDiscount?.customerGets?.value?.percentage,
          discountType:
            discount?.node?.codeDiscount?.customerGets?.value?.__typename,
        };
        setDiscountData(discountData);
      }
    } catch (err) {
      console.log("Something went wrong", err);
    }
  };

  useEffect(() => {
    const url = window.location.href;
    if (url.includes("discounts")) {
      const lastIndex = url.indexOf("discounts");
      const discountCodeName = url.slice(lastIndex + 10, url.length);
      getDiscountData(discountCodeName as string);
    }
  }, []);

  useEffect(() => {
    if (discountData) {
      localStorage.setItem("Discount code", JSON.stringify(discountData));
      navigate("/");
    }
  }, [discountData]);

  return <HomePageLayout />;
};

export default Discount;
